var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notfound_container" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c("img", {
          attrs: { src: require("./imgs/notfound_icon.png"), alt: "" }
        }),
        _c("div", { staticClass: "wrapper_text" }, [_vm._v(" 访问失败 ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }